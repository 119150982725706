import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { QuoteService } from './quote.service';
import {
    PRODUCTS,
    SERVICE_CARDS,
    AI_SERVICES,
    FAQS,
    TAILORED_SOL,
} from 'src/app/shared/constants/home';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BlogService } from 'src/app/blog/services/blog.service';
import { ContactUsServiceService } from '../ctas/contact-us-service.service';
import { AnnouncementService } from 'src/app/announcement/services/announcement.service';
declare var $: any;

interface TabContent {
    title: string;
    lead: string;
    description: string;
    details: string;
    buttonText: string;
    imageUrl: string; // Add this line
}

// Define the type for the content object
interface Content {
    [key: string]: TabContent;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['../../../app.common.scss', './home.component.scss'],
})
export class HomeComponent implements OnInit {
    quote: string | undefined;
    isLoading = false;

    serviceCards = SERVICE_CARDS;
    products = PRODUCTS;
    aiServices = AI_SERVICES;
    faqs = FAQS;
    tailored = TAILORED_SOL;
    isJqueryWorking = '';
    anchorPlacement = 'bottom-bottom';

    activeProductIndex = 0;
    activeNav = 1;
    responsiveFade = 'fade-left';
    activeGif: string | undefined = '';

    showGif(gif: string, index: number) {
        this.activeProductIndex = index;
        const videoElement = document.getElementById('productVideo') as HTMLVideoElement;
        const sourceElement = document.getElementById('productVideoSource') as HTMLSourceElement;
        sourceElement.src = `${gif}`;
        videoElement.load();
    }

    @ViewChild('typeTarget', { static: true }) typeTarget!: ElementRef;

    constructor(
        private quoteService: QuoteService,
        deviceService: DeviceDetectorService,
        private blogService: BlogService,
        private contactUsService: ContactUsServiceService,
        private announcementService: AnnouncementService
    ) {
        if (deviceService.isMobile()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }
        if (deviceService.isDesktop()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }
    }

    navigateToSignup() {

        window.open('https://app.genailia.com/dashboard', '_blank');

    }
    relatedBlogs: any[] = [];
    annoucements: any[] = [];


    getRelatedBlogs() {
        this.blogService.getBlogPosts(undefined, false, undefined, 3, "random").subscribe(res => {
            console.log(res);
            this.relatedBlogs = res;
        })

    }

    getAnnoucements() {
        this.announcementService.getBlogPosts(undefined, false, undefined, undefined, "random").subscribe(res => {
            this.annoucements = res;
            console.log(res);
        })
    }

    logandsignup(email: string) {
        window.open('https://app.genailia.com', '_blank');
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }
    public mobile = false;
    ngOnInit() {
        this.isLoading = true;
        $(document).ready(() => {
            this.isJqueryWorking = 'Welcome To Genailia.com';
            console.log(this.isJqueryWorking);
        });


        if (window.screen.width >= 767) { // 768px portrait
            this.mobile = true;
        }
        else {
            this.mobile = false;
        }

        this.getRelatedBlogs();
        this.getAnnoucements();

    }


    selectedTab: string = 'answer-agents';


    // Define the content object with the Content type
    content: Content = {
        'answer-agents': {
            title: 'Answer Agents',
            lead: 'While we can\'t fix all these problems, we can make things a bit easier.',
            description: 'Powered by the latest AI technologies, Amelia AI Agents are software that are always available to help customers and employees. AI agents communicate in natural language to answer questions and solve issues.',
            details: 'Our tried-and-true framework helps businesses quickly see the benefits of using AI agents, which typically fall into one of three categories: Answer Agents, Action Agents, and Autonomous Agents.',
            buttonText: 'Learn More',
            imageUrl: '../../../../assets/images/analytics.webp'
        },
        'action-agents': {
            title: 'Action Agents',
            lead: 'amma we can\'t fix all these problems, we can make things a bit easier.',
            description: 'Powered by the latest AI technologies, Amelia AI Agents are software that are always available to help customers and employees. AI agents communicate in natural language to answer questions and solve issues.',
            details: 'Our tried-and-true framework helps businesses quickly see the benefits of using AI agents, which typically fall into one of three categories: Answer Agents, Action Agents, and Autonomous Agents.',
            buttonText: 'Learn More',
            imageUrl: '../../../../assets/images/analytics.webp'
        },
        'autonomous-agents': {
            title: 'Autonomous Agents',
            lead: 'meri we can\'t fix all these problems, we can make things a bit easier.',
            description: 'Powered by the latest AI technologies, Amelia AI Agents are software that are always available to help customers and employees. AI agents communicate in natural language to answer questions and solve issues.',
            details: 'Our tried-and-true framework helps businesses quickly see the benefits of using AI agents, which typically fall into one of three categories: Answer Agents, Action Agents, and Autonomous Agents.',
            buttonText: 'Learn More',
            imageUrl: '../../../../assets/images/analytics.webp'
        }
    };

    selectTab(tab: string) {
        this.selectedTab = tab;
    }

    getContent(tab: string): TabContent {
        return this.content[tab];
    }


    openContactUsForm() {
        this.contactUsService.showPopup();
    }

    formatToSlugWithGuid(input: string, guid: string): string {

        const slug = input
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, '')
            .trim()
            .replace(/\s+/g, '-');

        return `${slug}-${guid}`;
    }


    tags = [
        {
            name: 'BankAssist'
        },
        {
            name: 'RetailAssist'
        },
        {
            name: 'HealthAssist'
        }
    ];
    tablecards = [1, 2, 3]
    cards = [
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl_CuYFKeD1ABTAIx58fstkVxUVXXYEfqQCA&s',
            title: 'Answer Agents',
            description: 'Agents that provide immediate answers to common queries.',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl_CuYFKeD1ABTAIx58fstkVxUVXXYEfqQCA&s',
            title: 'Action Agents',
            description: 'Agents that can perform specific actions on behalf of users.',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl_CuYFKeD1ABTAIx58fstkVxUVXXYEfqQCA&s',
            title: 'Autonomous Agents',
            description: 'Agents that can operate indepdsfsadkfas sakfl asfdkjsaljf asf asfjk;endently to achieve goals.',
        }
    ];


    tagCards = ['Model Hub', 'Prompt Studio', 'AI Agents', 'RLHF | RLAIF Evaluation Studio']

    logosrandom: string[] = [
        'assets/marquee/11.png',
        'assets/marquee/12.png',
        'assets/marquee/8.png',
        'assets/marquee/13.png',
        'assets/marquee/6.png',
        'assets/marquee/14.png',
        'assets/marquee/24.png',
        'assets/marquee/7.png',
        'assets/marquee/19.png',
        'assets/marquee/17.png',
        'assets/marquee/23.png',
        'assets/marquee/15.png',
        'assets/marquee/16.png',


        // Add more logos here
    ];
    logos: string[] = [
        'assets/marquee/6.png',
        'assets/marquee/7.png',
        'assets/marquee/8.png',
        'assets/marquee/11.png',
        'assets/marquee/12.png',
        'assets/marquee/13.png',
        'assets/marquee/14.png',
        'assets/marquee/15.png',
        'assets/marquee/16.png',
        'assets/marquee/17.png',
        'assets/marquee/19.png',
        'assets/marquee/23.png',
        'assets/marquee/24.png',


        // Add more logos here
    ];
    logos2: string[] = [
        'assets/img/28.png',
        'assets/img/29.png',
        'assets/img/30.png',
        'assets/img/31.png',
        'assets/img/25.png',
        'assets/img/26.png',
        'assets/img/27.png'
    ];

    logos3: string[] = [
        'assets/img/5.png',
        'assets/img/20.png',
        'assets/img/3.png',
        'assets/img/22.png',
        'assets/img/21.png',
    ];




    get reversedLogos(): string[] {
        return [...this.logosrandom];
    }

    get reversedLogos2(): string[] {
        return [...this.logos3].reverse(); // Create a new reversed array
    }

    HeroTags: any[] = [
        'Generative AI',
        'TTS',
        'Translation',
        'Transcription',
        'Transliteration',
        'OCR',
        '100+ Languages',
        'Live Speech Translation and Transcription',
        'Text and Spoken Language Detection',
        'Social Media Insights'
    ]
    HeroTagsNew: any = [
        'Generative AI',
        'Private LLM',
        'Multi-lingual Voice-enabled ChatBots',
        'LangChain',
        'LLaMaIndex',
        'Retrieval-Augmented Generation',
        'Vector DB',
        'NLP',
        'Images Generation',
        'Image Explanation'
    ]

    isFlipped: boolean[] = [];

    onMouseEnter(index: number) {
        this.isFlipped[index] = true;
    }

    onMouseLeave(index: number) {
        this.isFlipped[index] = false;
    }


}
