<div class="mt-86 overflow-hidden">
	<!-- Hero section -->
	<section>
		<ngb-carousel [pauseOnHover]="true" [showNavigationArrows]="mobile"
			[showNavigationIndicators]="false" [interval]="5000">
		<ng-template ngbSlide>
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0">
				<h1 class="font-weight-light mb-0">
					<a target="_blank" href="https://app.genailia.com">Try <b>Genailia</b>
					</a>
				</h1>
				<h2>
					- Generative AI, <br />Language Interfaces,
				</h2>
				<h2
					class="font-weight-bold lh-1 d-flex align-items-center centeralign">
					<span class="bg-warning rounded ml-2 px-2 py-1"> <ngx-typed-js
							[strings]="[
                    'Actions',
                    'Accelerators',
                    'Analytics',
                    'Agents',
                    'Assistants',
                    'Applications'
                  ]"
							[shuffle]="false" [loop]="true" [typeSpeed]="30">
						<span class="typing"></span> </ngx-typed-js>
					</span>
				</h2>
				<p class="font-weight-normal d-flex flex-wrap custom-style p-3">
					<!-- <br />Generative AI | TTS | Translation | Transcription |
              Transliteration <br />100+ Languages | Live Speech Translation
              &amp; Transcription | OCR <br />Text &amp; Spoken Language
              Detection | Social Media Insights -->

					<span class="badge custom-tag" *ngFor="let tag of HeroTags">{{tag}}</span>
				</p>

				<div>
					<!-- <input type="email" #emailInput
						class="form-control form-control-alternative border bg-lightest add16"
						placeholder="name@example.com" /> -->
					<button type="submit" class="btn btn-primary btn-icon mt-3"
						(click)="navigateToSignup()">
						Sign up <i class="fa-solid fa-arrow-right-long ml-3"></i>
					</button>
				</div>
			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="../../../../assets/img/landing0.jpg" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide>
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0">
				<h4 class="font-weight-light mb-0">
					Use <a target="_blank"
						href="https://app.genailia.com/generative-ai/ellmo"><u>Genailia's
							eLLMo</u> for </a>
				</h4>

				<h2
					class="font-weight-bold lh-1 d-flex align-items-center centeralign">
					<span class="bg-warning rounded ml-2 px-2 py-1"> <ngx-typed-js
							[strings]="[
                    'Generative AI',
                    'Multiple AI Models',
                    'Image Generation',
                    'Role-based Usage',
                    'GPTs | LLMs | RAG | Agents'
                  ]"
							[shuffle]="false" [loop]="true" [typeSpeed]="30">
						<span class="typing"></span> </ngx-typed-js>
					</span>
				</h2>



				<p class="font-weight-normal d-flex flex-wrap custom-style p-3">

					<!-- Generative AI | Private LLM | Multi-lingual Voice-enabled ChatBots
				<br />LangChain | LLaMaIndex | Retrieval-Augmented Generation
				<br />Vector DB | Images Generation | Image Explanation | NLP -->
					<span class="badge custom-tag" *ngFor="let tag of HeroTagsNew">{{tag}}</span>
				</p>

				<div>
					<!--<input type="email" #emailInput
						class="form-control form-control-alternative border bg-lightest add16"
						placeholder="name@example.com" /> -->
					<!-- <button
                type="submit"
                class="btn btn-primary btn-icon mt-3"
                (click)="logandsignup(emailInput.value)"
              >
                Sign up
                <i class="fa-solid fa-arrow-right-long ml-3"></i>
              </button> -->
					<button type="submit" class="btn btn-primary btn-icon mt-3"
						(click)="navigateToSignup()">
						Sign up <i class="fa-solid fa-arrow-right-long ml-3"></i>
					</button>
				</div>
			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto"
					alt="eLLMo - Enterprise Large Language Model Outcomes"
					src="../../../../assets/img/landing1.jpg" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide>
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0">
				<h2 class="font-weight-light mb-0">Home of</h2>
				<h2 class="font-weight-bold lh-1">Translation, Transcription, &
					Transliteration</h2>

				<p class="font-weight-normal mt-5">Support for all languages
					spoken on our planet</p>

				<button (click)="openContactUsForm()" type="submit"
					class="btn btn-warning btn-icon">Contact us for details</button>
			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="assets/img/landing2.jpg" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide>
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0">
				<h2 class="font-weight-light mb-0">TTS &amp; ASR</h2>
				<h2 class="font-weight-bold lh-1">Transcript using our Speech
					to Text Capabilities</h2>

				<p class="font-weight-normal mt-5 mb-0">Get Text to Speech (TTS)
					- Natural or Neural</p>
				<p class="font-weight-normal font-italic mb-5">Emotional TTS |
					Voice Cloning | 2500+ voices | Text to Music</p>

				<a target="_blank" rel="noreferrer noopener"
					href="https://calendly.com/genailia/30min"
					class="btn btn-warning btn-icon"> <i
					class="fa-regular fa-calendar mr-2"></i>Consult with us
				</a>
			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="assets/img/landing3.webp" />
			</div>
		</div>
		</ng-template> <ng-template ngbSlide>
		<div class="row genzslider">
			<div
				class="col-12 col-md-6 m-auto pl-3 pl-md-5 pb-5 pb-md-0 pt-3 pt-md-0">
				<h2 class="font-weight-light mb-0">Mix and Match</h2>
				<h2 class="font-weight-bold lh-1">Our Products - like Puzzle
					Pieces</h2>

				<p class="font-weight-normal mt-5 mb-0">All our products come
					with REST APIs</p>
				<p class="font-weight-normal font-italic mb-5">that let you
					create a unique solution for your needs!</p>

				<a target="_blank" rel="noreferrer noopener"
					href="https://app.genailia.com/dashboard"
					class="btn btn-warning btn-icon"> <i
					class="fa-regular fa-calendar mr-2"></i>Sign in
				</a>
			</div>

			<div class="d-none d-md-block col-12 col-md-6 mask1 pl-0">
				<img width="100%" height="auto" alt="genailia.com"
					src="assets/img/landing4.jpeg" />
			</div>
		</div>
		</ng-template> </ngb-carousel>
	</section>
	<div class="row">
		<div class="col-12 text-center mb-4">
			<div class="flex justify-content-center align-items-center">
				<div class="icon-box mx-auto p-3 text-purple font-weight-light">
					<i class="fa-solid fa-newspaper add2"></i>
				</div>
				<h3 class="font-weight-bold mt-4" data-aos="fade-up">
					<span class="font-weight-light">Proudly</span> Associated With
				</h3>
			</div>
		</div>
	</div>
	<br /> <br />
	<div class="py-3 marqueenew">


		<div class="logo-marquee-container">
			<div class="logo-marquee marquee-left-to-right">
				<div *ngFor="let logo of reversedLogos" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid">
				</div>



			</div>
			<div class="logo-marquee marquee-right-to-left mt-2">
				<div *ngFor="let logo of logos" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid">
				</div>


			</div>
		</div>
	</div>



	<!-- Hero section -->


	<app-platform></app-platform>

	<br /> <br />
	<!-- Platform Features Diagram Section -->
	<div class="survey-background-container ">
		<div class="platform-features surveycontainer ">
			<h2 class="surveycontainer">Explore Genailia's Unified Platform</h2>

			<!-- Survey Invitation Section -->
			<div class="survey-invitation">
				<p>At Genailia, we strive to revolutionize how businesses handle
					multilingual content and conversational AI. We're interested in
					understanding what products you currently utilize and how we can
					enhance your experience. Take our brief survey to compare what you
					get from competitors and discover the unique benefits of Genailia.
					Your insights are invaluable in helping us serve you better!</p>
				<a href="https://surveys.genailia.com/zs/Nz60jY" target="_blank"
					class="survey-button">Take the Survey</a><br /> <br /> <br />
			</div>
		</div>
	</div>
	<br /> <br /> <br />
	<!-- Our offerings -->
	<!-- Our products -->
	<!-- Our products -->
	<section class="container-fluid">
		<div class="row">
			<div class="col-12 text-center mb-4">
				<div class="flex justify-content-center align-items-center">
					<div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-robot add2"></i>
					</div>
					<h3 class="font-weight-bold mt-4" data-aos="fade-up">
						<span class="font-weight-light">Genailia</span> Modules
					</h3>
				</div>
			</div>

			<div class="d-none d-md-block col-3 border-left pl-4">
				<div data-aos="fade-up" [attr.data-aos-delay]="150 * index"
					data-aos-anchor-placement="top-bottom"
					*ngFor="let product of products; let index = index"
					[ngClass]="activeProductIndex === index ? 'active-product' : ''">
					<div class="product-border"></div>
					<p (click)="activeProductIndex = index"
						class="py-2 cursor-pointer add233">&nbsp;&nbsp;&nbsp;&nbsp;{{
						product.title }}</p>
				</div>
			</div>

			<div
				class="d-none d-md-block col-8 bg-purple-100 rounded pt-3 text-center shadow-sm h-fit"
				[attr.data-aos]="responsiveFade"
				data-aos-anchor-placement="top-bottom">
				<p class="text-primary1 font-weight-bold">{{
					products[activeProductIndex].description }}</p>
				<div class="relative">
					<img class="img-fluid rounded cursor-pointer"
						[src]="'assets/img/product' + (activeProductIndex + 1) + '.webp'"
						alt="Product Image {{ activeProductIndex + 1 }}" type="button"
						(click)="
              showGif(products[activeProductIndex].gif, activeProductIndex)
            "
						data-bs-toggle="modal" data-bs-target="#exampleModal1" />
					<div class="play-button"
						(click)="
              showGif(products[activeProductIndex].gif, activeProductIndex)
            "
						data-bs-toggle="modal" data-bs-target="#exampleModal1">
						<i class="fa fa-play-circle"></i>
					</div>
				</div>
				<button [routerLink]="products[activeProductIndex].route"
					type="button" class="btn btn-primary btn-round btn-icon my-33">
					<i class="fa-solid fa-shapes mr-2"></i> Try Now
				</button>
			</div>

			<div class="d-md-none col-12" data-aos="fade-up">
				<ngb-carousel [pauseOnHover]="true" [showNavigationArrows]="true"
					[showNavigationIndicators]="false" [interval]="7000">
				<ng-template ngbSlide
					*ngFor="let product of products; let index = index">
				<p class="mb-0 font-weight-bold h5 text-purple text-center">{{
					index > 8 ? "" : "0" }}{{ index + 1 }}. {{ product.title }}</p>
				<div class="product-border-mobile mb-3"></div>
				<div
					class="bg-purple-100 rounded pt-3 text-center shadow-sm p-2 mb-5">
					<p class="text-sm text-primary font-weight-bold">{{
						product.description }}</p>
					<img class="img-fluid rounded cursor-pointer"
						[src]="'assets/img/product' + (index + 1) + '.webp'"
						alt="Product Image {{ index + 1 }}"
						(click)="showGif(product.gif, index)" data-bs-toggle="modal"
						data-bs-target="#exampleModal1" />
					<div class="play-button" (click)="showGif(product.gif, index)"
						data-bs-toggle="modal" data-bs-target="#exampleModal1">
						<i class="fa fa-play-circle"></i>
					</div>
					<button [routerLink]="product.route" type="button"
						class="btn btn-primary btn-round btn-icon mt-3 btn-sm">
						<i class="fa-solid fa-shapes mr-2"></i> Try Now
					</button>
				</div>
				</ng-template> </ngb-carousel>
			</div>

			<div class="modal" id="exampleModal1" tabindex="-1"
				aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered custom-modal-width">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel"></h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal"
								aria-label="Close"></button>
						</div>
						<div class="modal-body text-center">
							<video id="productVideo" class="img-fluid rounded" controls
								autoplay loop muted>
								<source id="productVideoSource" type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- 
	<section class="container-fluid">
		<div class="row">
			<div class="col-12 text-center mb-5" data-aos="fade-up"
				data-aos-offset="100" data-aos-delay="80">
				<div class="flex justify-content-center align-items-center">
					<div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-lightbulb add2"></i>
					</div>
					<h3 class="font-weight-bold mt-4">
						<span class="font-weight-light">The
						</span>
						Challenge
					</h3>
				</div>
			</div>
		</div>
	<div class="py-5 bg-light">
		<div class="container text-center">
			<h2 class="mb-4 font-weight-bold">Today's Enterprises Face Mounting Pressure</h2>
			<p class="lead mb-5">
				Customer expectations are through the roof. Employees feel burned out and disengaged. 
				And all the while, the pace of AI innovation is causing both excitement and confusion in the market.
			</p>
			<div class="row">
				<div class="col-md-4">
					<div class="card border-0 shadow-sm">
						<div class="card-body">
							<h3 class="card-title display-4">72%</h3>
							<p class="card-text">Of Customers Want Immediate Service</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card border-0 shadow-sm">
						<div class="card-body">
							<h3 class="card-title display-4">77%</h3>
							<p class="card-text">Of Employees Are Not Engaged At Work</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card border-0 shadow-sm">
						<div class="card-body">
							<h3 class="card-title display-4">50%</h3>
							<p class="card-text">Of IT Leaders Are Struggling to Drive Value With AI</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="container">
	<div class="row mb-4 ">
	  <div class="col text-center rounding-new" 
		   [ngClass]="{'bg-custom': selectedTab === 'answer-agents'}"
		   (click)="selectTab('answer-agents')">
		<span class="py-3 mb-0">Answer Agents</span>
	  </div>
	  <div class="col text-center rounding-new" 
		   [ngClass]="{'bg-custom': selectedTab === 'action-agents'}"
		   (click)="selectTab('action-agents')">
		<span class="py-3 mb-0">Action Agents</span>
	  </div>
	  <div class="col text-center rounding-new" 
		   [ngClass]="{'bg-custom': selectedTab === 'autonomous-agents'}"
		   (click)="selectTab('autonomous-agents')">
		<span class="py-3 mb-0">Autonomous Agents</span>
	  </div>
	</div>
	<div class="tab-content" id="myTabContent">
	  <div class="tab-pane fade show active" [ngClass]="{'show active': selectedTab === 'answer-agents'}">
		<div class="row">
		  <div class="col-md-6">
			<h2 class="mb-4">{{getContent('answer-agents').title}}</h2>
			<p class="lead">{{getContent('answer-agents').lead}}</p>
			<p>{{getContent('answer-agents').description}}</p>
			<p>{{getContent('answer-agents').details}}</p>
			<a href="#" class="btn btn-primary mt-4">{{getContent('answer-agents').buttonText}}</a>
		  </div>
		  <div class="col-md-6 text-center">
			<img [src]="getContent('answer-agents').imageUrl" class="img-fluid image-round" alt="Answer Agents">
		  </div>
		</div>
	  </div>
  
	  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'action-agents'}">
		<div class="row">
		  <div class="col-md-6">
			<h2 class="mb-4">{{getContent('action-agents').title}}</h2>
			<p class="lead">{{getContent('action-agents').lead}}</p>
			<p>{{getContent('action-agents').description}}</p>
			<p>{{getContent('action-agents').details}}</p>
			<a href="#" class="btn btn-primary mt-4">{{getContent('action-agents').buttonText}}</a>
		  </div>
		  <div class="col-md-6 text-center">
			<img [src]="getContent('action-agents').imageUrl" class="img-fluid image-round" alt="Action Agents">
		  </div>
		</div>
	  </div>
  
	  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'autonomous-agents'}">
		<div class="row">
		  <div class="col-md-6">
			<h2 class="mb-4">{{getContent('autonomous-agents').title}}</h2>
			<p class="lead">{{getContent('autonomous-agents').lead}}</p>
			<p>{{getContent('autonomous-agents').description}}</p>
			<p>{{getContent('autonomous-agents').details}}</p>
			<a href="#" class="btn btn-primary mt-4">{{getContent('autonomous-agents').buttonText}}</a>
		  </div>
		  <div class="col-md-6 text-center">
			<img [src]="getContent('autonomous-agents').imageUrl" class="img-fluid image-round" alt="Autonomous Agents">
		  </div>
		</div>
	  </div>
	</div>
  </div>
  
    <br />
    <br /> <br />
    <br />
	<!-- Our products -->
</div>
<div class="py-3 container"
	*ngIf="relatedBlogs && relatedBlogs.length > 0;">
	<h2 class="mb-6">Announcements</h2>
	<br />
	<div class="post-container">
		<div *ngFor="let post of annoucements" class="post">
			<a
				[routerLink]="['../announcement/post', formatToSlugWithGuid(post.title,post.guid)]">
				<img [src]="post.imageUrl" alt="Post Image"
				class="post-image w-24 h-24 object-cover mx-auto"> <!-- Fixed size with centering -->
				<div class="post-content">
					<h2 class="post-title">{{ post.title }}</h2>
					<p class="post-description" [title]="post.description">{{
						post.description }}</p>
					<div class="post-meta">
						<span>Category: {{ post.category }}</span> <span>By {{
							post.creator }}</span>
					</div>
				</div>
			</a>
		</div>
	</div>

</div>



<div class="container-fluid">


	<br /> <br /> <br /> <br />

	<!-- Our services -->
	<section class="container">
		<div class="row">
			<div class="col-12 text-center mb-5" data-aos="fade-up"
				data-aos-offset="100" data-aos-delay="80">
				<div class="flex justify-content-center align-items-center">
					<div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-shuttle-space add2"></i>
					</div>
					<h3 class="font-weight-bold mt-4">
						<a routerLink="/ai-capabilities" class="font-weight-light">Our
							AI Capabilities</a>&nbsp;-&nbsp; <a routerLink="/ai-transformation">Your
							AI Transformation</a>
					</h3>
				</div>
			</div>
		</div>
		<!-- class="row" is removed and added myclass -->
		<div class="myclass">
			<div class="add4 col-12 col-md-3 mb-3 mb-md-0"
				*ngFor="let card of serviceCards; let index = index"
				[attr.data-aos-delay]="200 * index" data-aos="fade-right">
				<div class="card service-box text-center d-block h-100">
					<i [ngClass]="card.iconClass"></i>

					<h6 class="font-weight-bold mt-3">{{ card.title }}</h6>
					<p
						class="service-box_description font-weight-normal mt-4 mb-0 text-xs">
						{{ card.description }}</p>
				</div>
			</div>
		</div>
	</section>
	<br /> <br /> <br /> <br />
	<!-- 
	<section class="py-5">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-6">
					<h2 class="mb-4">The Solution: Amelia AI Agents</h2>
					<p class="lead">
						While we can't fix all these problems, we can make things a bit easier.
					</p>
					<p>
						Powered by the latest AI technologies, Amelia AI Agents are software that are always available to help customers and employees. AI agents communicate in natural language to answer questions and solve issues.
					</p>
					<p>
						Our tried-and-true framework helps businesses quickly see the benefits of using AI agents, which typically fall into one of three categories: Answer Agents, Action Agents, and Autonomous Agents.
					</p>
					<a href="#" class="btn btn-primary mt-4">Learn More</a>
				</div>
				<div class="col-md-6">
					<img src="../../../../assets/images/computer-vision.webp" class="img-fluid" alt="Amelia AI Agents">
				</div>
			</div>
		</div>
	</section>
	<div class="row">
		<div class="col-12 text-center mb-5" data-aos="fade-up"
			data-aos-offset="100" data-aos-delay="80">
			<div class="flex justify-content-center align-items-center">
				<div class="icon-box mx-auto p-3 text-purple font-weight-light">
					<i class="fa-solid fa-shuttle-space add2"></i>
				</div>
				<h3 class="font-weight-bold mt-4">
					<span class="font-weight-light">
						Our 
					</span>
					Enterprise-Grade Features
				</h3>
			</div>
		</div>
	</div>
	<section class="py-5">
		<div class="container">
			<div class="row">
				<div class="col-md-4" *ngFor="let card of cards">
					<div class="custom-card shadow-sm">
						<div class="custom-card-body d-flex flex-column align-items-center">
							<img [src]="card.icon" alt="Icon" class="custom-card-icon img-fluid">
							<h5 class="custom-card-title text-center">{{ card.title }}</h5>
							<p class="custom-card-text text-center">{{ card.description }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	
	

	<!-- Our services -->

	<!-- Try our products -->
	<section class="container">
		<!-- <div class="row">
      <div class="col-12 text-center my-auto">
        <h3 class="font-weight-bold mb-4">Get a walkthrough of our products or See them for yourself!</h3>

        <div class="d-block d-md-flex justify-content-center" style="gap: 1rem">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://calendly.com/genailia/30min"
            class="btn btn-neutral btn-icon"
          >
            <i class="fa-regular fa-calendar mr-2"></i>
            Schedule a quick call
          </a>

          <button type="button" class="btn btn-warning btn-icon mt-4 mt-md-0">
            Signup to try our products!
            <i class="fa-solid fa-arrow-right-long ml-2"></i>
          </button>
        </div>
      </div>
    </div> -->

		<div class="row">
			<div class="col-12 add3 touch-card text-center">
				<div class="position-relative w-100 h-100">
					<!-- <img class="wave1 position-absolute d-none d-md-block" src="assets/img/wave1.svg" alt="Leader" />
                    <img class="wave2 position-absolute d-none d-md-block" width="60%" src="assets/img/wave2.webp"
                        alt="Leader" /> -->

					<img class="person1 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/test2/1.webp" alt="Bot" /> <img
						class="person2 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/test2/2.webp" alt="ellmo" /> <img
						class="person3 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/test2/3.webp" alt="ellmo" /> <img
						class="person4 position-absolute d-none d-md-block add15"
						width="90" height="90" src="assets/test2/1.webp" alt="Bot" />

					<div class="touch-card_content mx-auto z-index-10">
						<h3 class="font-weight-bold mb-4 md-mb-5 text-white mx-auto"
							data-aos="fade-up" data-aos-offset="100" data-aos-delay="80"
							[attr.data-aos-anchor-placement]="anchorPlacement"
							data-aos="fade-up">Get a walkthrough of our products or See
							them for yourself!</h3>
						<!-- <a target="_blank" rel="noreferrer noopener"
							href="https://calendly.com/genailia/30min"
							class="btn btn-neutral btn-icon d-block w-fit mx-auto"
							data-aos="fade-up" data-aos-delay="150"
							[attr.data-aos-anchor-placement]="anchorPlacement"> <i
							class="fa-regular fa-calendar mr-2"></i> Schedule a quick call
						</a> -->
						<div>
							<!-- Calendly link widget begin -->
							<link
								href="https://assets.calendly.com/assets/external/widget.css"
								rel="stylesheet" />
							<a href=""
								onclick="Calendly.initPopupWidget({url: 'https://calendly.com/genailia'});return false;"
								class="btn btn-neutral btn-icon d-block w-fit mx-auto"
								data-aos="fade-up" data-aos-delay="150"
								[attr.data-aos-anchor-placement]="anchorPlacement"> <i
								class="fa-regular fa-calendar mr-2"></i> Schedule a quick call
							</a>
							<!-- Calendly link widget end -->
						</div>

						<button (click)="navigateToSignup()" type="button"
							class="btn btn-warning btn-icon mt-4" data-aos="fade-up"
							data-aos-delay="150"
							[attr.data-aos-anchor-placement]="anchorPlacement">
							Signup to try our products! <i
								class="fa-solid fa-arrow-right-long ml-2"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- Try our products -->
	<br /> <br /> <br /> <br />
	<section class="container">
		<div class="row">
			<div class="col-12 col-md-5" data-aos="fade-right"
				data-aos-delay="100" data-aos-offset="50">
				<h3 class="mb-0 font-weight-light lh-1 mt-4">Gen-AI-LI-A</h3>
				<h3 class="font-weight-bold">Revolution</h3>

				<p class="">Empowering AI transformations since 2021, we
					identified the unmet need for advanced AI solutions tailored to
					startups, SMEs, and enterprises. Our innovative, scalable products
					drive business growth by breaking down barriers in a dynamic
					market. With Genailia, experience seamless integration of AI
					capabilities that accelerate your success and position your
					business at the forefront of the AI revolution.</p>

				<div class="d-block d-md-flex mt-5 custom-gap">
					<a target="_blank" rel="noreferrer noopener"
						href="https://calendly.com/genailia/30min"
						class="btn btn-warning btn-icon"> <i
						class="fa-regular fa-calendar mr-2"></i> Consult with us
					</a>
					<button type="button" class="btn btn-neutral btn-icon mt-4 mt-md-0"
						routerLink="/contact">
						<i class="fa-regular fa-envelope mr-2"></i> Contact us
					</button>
				</div>
			</div>

			<div class="d-none d-md-block col-md-2 my-lg-auto mt-4"
				[attr.data-aos]="responsiveFade">
				<img class="img-fluid rounded mb-4"
					src="assets/img/feature-two-1.webp" alt="feature-two-1" /> <img
					class="img-fluid rounded" src="assets/img/feature-two-2.webp"
					alt="feature-two-2" />
			</div>

			<div class="d-none d-md-block col-md-3 mt-lg-0 mt-4">
				<img class="img-fluid rounded" src="assets/img/feature-two-3.webp"
					alt="feature-two-3" />
			</div>

			<div class="d-none d-md-block col-md-2 my-lg-auto mt-4"
				data-aos="fade-right">
				<img class="img-fluid rounded" src="assets/img/feature-two-4.webp"
					alt="feature-two-4" />
			</div>
		</div>
	</section>
	<br /> <br /> <br /> <br />
	<!-- Our clients -->
	<div class="row">
		<div class="col-12 text-center mb-4">
			<div class="flex justify-content-center align-items-center">
				<div class="icon-box mx-auto p-3 text-purple font-weight-light">
					<i class="fa-solid fa-newspaper add2"></i>
				</div>
				<h3 class="font-weight-bold mt-4" data-aos="fade-up">
					<span class="font-weight-light">Our</span> Clients &amp; Partners
				</h3>
			</div>
		</div>
	</div>
	<br /> <br />
	<div class="py-3 marqueenew">


		<div class="logo-marquee-container">
			<div class="logo-marquee marquee-left-to-right">
				<div *ngFor="let logo of reversedLogos2" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid">
				</div>



			</div>
			<div class="logo-marquee marquee-right-to-left mt-2">
				<div *ngFor="let logo of logos2" class="logo-item">
					<img [src]="logo" alt="Logo" class="img-fluid">
				</div>


			</div>
		</div>
	</div>


	<!-- Our clients -->
	<br /> <br /> <br /> <br />
	<!-- AI services -->
	<section class="container ">
		<div class="row">
			<div class="col-12 col-md-5" data-aos="fade-up">
				<h3 class="mb-0 font-weight-light lh-1 mt-4" data-aos="fade-right">
					Affordable &amp; Democratized</h3>
				<h3 class="font-weight-bold" data-aos="fade-right">AI for
					everyone</h3>

				<p class="" data-aos="fade-right">Genailia delivers diverse
					solutions for diverse applications, ensuring that the technology
					provided is reliable, trustworthy, and cutting-edge. With a strong
					commitment to the latest AI innovations, Genailia empowers
					businesses with exceptional possibilities. These democratized,
					affordable solutions make advanced AI accessible to all, without
					compromising on quality or security. Emphasizing best-in-class
					standards of excellence and confidentiality, Genailia builds
					strong, trusted partnerships, helping clients achieve their goals
					with confidence.</p>
			</div>

			<div class="col-12 col-md-7">
				<div class="row">
					<div class="col-6 col-md-4 mb-4 text-center" data-aos="fade-left"
						[attr.data-aos-delay]="100 * index"
						*ngFor="let card of aiServices; let index = index">
						<div class="card aiCard pt-4">
							<img class="rounded-circle mx-auto aiCard_img" width="100"
								height="100"
								[src]="'assets/img/aiservice' + (index + 1) + '.webp'"
								alt="aiservice {{ index + 1 }}" />
							<div class="mt-auto pb-3">
								<p class="text-primary font-weight-bold">{{ card.title }}</p>
								<a rel="noreferrer noopener" [href]="card.route"
									class="text-sm text-primary mx-auto tailored-link"> Know
									more <i class="fa-solid fa-arrow-right-long ml-2"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>


	<br /> <br /> <br /> <br />
	<!-- AI services -->
	<!-- Tailored solution -->

	<section class="container-fluid">
		<div class="row">
			<div class="col-12 col-md-12 mb-4 text-center" data-aos="fade-up"
				[attr.data-aos-anchor-placement]="anchorPlacement">
				<div class="flex justify-content-center align-items-center">
					<div class="icon-box mx-auto p-3 text-purple font-weight-light">
						<i class="fa-solid fa-shuttle-space add2"></i>
					</div>
					<h3 class="font-weight-bold mt-4">
						<span class="font-weight-light">Boosting Customer, Agent,
							&amp; Employee</span> Experience Across Domains!
					</h3>
				</div>
			</div>
			<div data-aos="fade-up" [attr.data-aos-delay]="80 * index"
				class="col-12 col-md-4 col-lg-3 text-center py-4 cursor-pointer tailored-card rounded logo-img"
				*ngFor="let sol of tailored; let index = index">
				<a [routerLink]="sol.link"> <img class="mb-3" alt="property"
					[src]="'assets/test4/ts' + (index + 1) + '.svg'" />

					<p class="mb-1 font-weight-bold">{{ sol.title }}</p>
					<p class="text-sm">{{ sol.desc }}</p></a>
			</div>
		</div>
	</section>

	<div class="container py-3"
		*ngIf="relatedBlogs && relatedBlogs.length > 0;">
		<h2 class="mb-6">Our Recent Blogs</h2>
		<br />
		<div class="post-container">
			<div *ngFor="let post of relatedBlogs" class="post">
				<a
					[routerLink]="['../blog/post', formatToSlugWithGuid(post.title,post.guid)]">
					<img [src]="post.imageUrl" alt="Post Image"
					class="post-image w-24 h-24 object-cover mx-auto"> <!-- Fixed size with centering -->
					<div class="post-content">
						<h2 class="post-title">{{ post.title }}</h2>
						<p class="post-description" [title]="post.description">{{
							post.description }}</p>
						<div class="post-meta">
							<span>Category: {{ post.category }}</span> <span>By {{
								post.creator }}</span>
						</div>
					</div>
				</a>
			</div>
		</div>

	</div>
	<br /> <br /> <br /> <br />

	<!-- FAQ -->
	<section class="container">
		<div class="row">
			<!-- <div class="col-12 col-md-4">
        <div class="faq-img position-relative">
          <img class="img-fluid rounded" src="assets/img/faq.webp" alt="" />
          <span class="faq-ask text-uppercase rounded text-white bg-primary fs-16 fw-medium px-4 py-3"
            >Ask us anything</span
          >
        </div>
      </div> -->
			<div class="col-12 col-md-12">
				<div id="faq-anchor"
					class="d-flex align-items-center mb-4 custom-gap"
					[attr.data-aos]="responsiveFade" data-aos-anchor="#faq-anchor"
					[attr.data-aos-anchor-placement]="anchorPlacement">
					<div class="icon-box p-3 text-purple font-weight-light">
						<i class="fa-solid fa-question add2"></i>
					</div>
					<div class="">
						<h3 class="mb-0 font-weight-light lh-1 mt-4">FAQ's</h3>
						<h3 class="font-weight-bold">Frequently asked questions</h3>
					</div>
				</div>

				<ul id="faq-anchor2" ngbNav #nav="ngbNav" [(activeId)]="activeNav"
					class="nav-pills">
					<li [ngbNavItem]="1" [attr.data-aos]="responsiveFade"
						data-aos-anchor="#faq-anchor2"
						[attr.data-aos-anchor-placement]="anchorPlacement"><a
						ngbNavLink>General</a> <ng-template ngbNavContent> <ng-container
							*ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

					<li [ngbNavItem]="2" [attr.data-aos]="responsiveFade"
						data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="200"
						[attr.data-aos-anchor-placement]="anchorPlacement"><a
						ngbNavLink>Pricing</a> <ng-template ngbNavContent> <ng-container
							*ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

					<li [ngbNavItem]="3" [attr.data-aos]="responsiveFade"
						data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="400"
						[attr.data-aos-anchor-placement]="anchorPlacement"><a
						ngbNavLink>Languages</a> <ng-template ngbNavContent>
						<ng-container *ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

					<li [ngbNavItem]="4" [attr.data-aos]="responsiveFade"
						data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="600"
						[attr.data-aos-anchor-placement]="anchorPlacement"><a
						ngbNavLink>Products</a> <ng-template ngbNavContent>
						<ng-container *ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

					<li [ngbNavItem]="5" [attr.data-aos]="responsiveFade"
						data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="600"
						[attr.data-aos-anchor-placement]="anchorPlacement"><a
						ngbNavLink>Enterprise</a> <ng-template ngbNavContent>
						<ng-container *ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>

					<li [ngbNavItem]="6" [attr.data-aos]="responsiveFade"
						data-aos-anchor="#faq-anchor2" [attr.data-aos-delay]="600"
						[attr.data-aos-anchor-placement]="anchorPlacement"><a
						ngbNavLink>Support</a> <ng-template ngbNavContent> <ng-container
							*ngTemplateOutlet="faqTemplate"></ng-container> </ng-template></li>
				</ul>

				<div [ngbNavOutlet]="nav" class="mt-4"></div>
			</div>
		</div>
	</section>
	<!-- FAQ -->

	<!-- Contact us -->
	<app-contact></app-contact>
	<!-- Contact us -->

	<!--
<div class="row">
  <div class="col-md-6">
    <div class="form-group has-success">
      <input type="text" placeholder="Success" class="form-control form-control-alternative is-valid" />
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group has-danger">
      <input type="email" placeholder="Error Input" class="form-control form-control-alternative is-invalid" />
    </div>
  </div>
</div> -->

	<ng-template #faqTemplate> <ngb-accordion
		#acc="ngbAccordion" activeIds="ngb-panel-0"> <ngb-panel
		[title]="faq.question" *ngFor="let faq of faqs[activeNav - 1]">
	<ng-template ngbPanelContent>
	<div [innerHTML]="faq.answer"></div>
	</ng-template> </ngb-panel> </ngb-accordion> </ng-template>
</div>
