import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlossaryDataService } from '../glossary-data.service';
@Component({
  selector: 'app-glossary',
  standalone: true,
  imports: [CommonModule,RouterModule],
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent {
  glossaryItems : any[] = []
  glossaryService :any = inject(GlossaryDataService)

  constructor(){
    this.glossaryItems = this.glossaryService.getAllkeywords()
  }

   }
