<br/><br/><br/><br/>
<footer class="container-fluid border-top position-relative custom-style">
  <div class="custom-container bgyellow">
    <h2 class="title ">Genailia: Harnessing AI to Redefine Possibilities</h2>
    <p class="description">
      Generative AI, Linguistic Interfaces, Accelerators, and more, all in one platform.
    </p>
    <a href="https://www.app.genailia.com" target="_blank" class="cta-button">Get Started</a>
  </div>
  <div class="container ">
    <div class="row pb-5 custom-pad">
      <div class="col-12 col-md-4 text-center text-md-left">
        <p class="font-weight-bold mt-3 mb-2">Stay Updated!</p>
        <p class="text-sm mb-4">Subscribe to our newsletter for the latest news and updates.</p>
      
        <form action="/subscribe" method="post" class="d-flex align-items-center">
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            class="form-control mr-2"
            required
          />
          <button
            type="submit"
            class="btn custom-button font-weight-bold"
          >
            Subscribe
          </button>
        </form>
      </div>
      

      <div class="col-12 col-md-3 text-center text-md-left">
        <p class="font-weight-bold mt-3 mb-0">Our Products</p>
        <a
          rel="noreferrer noopener"
          [routerLink]="product.link"
          class="text-sm mb-2 d-block text-white font-weight-semibold"
          *ngFor="let product of products"
          >{{ product.title }}</a
        >
      </div>

      <div class="col-12 col-md-2 text-center text-md-left">
        <p class="font-weight-bold mt-3 mb-0">Our Menu</p>
        <a
          rel="noreferrer noopener"
          [href]="menu.link"
          class="text-sm mb-2 d-block text-white font-weight-semibold"
          *ngFor="let menu of menuItems"
          >{{ menu.title }}</a
        >
      </div>

      <div class="col-12 col-md-3 text-center text-md-left">
        <p class="font-weight-bold mt-3 mb-0">Social media</p>
        <div
          class="d-flex align-items-center justify-content-center justify-content-md-start custom-gap"
        >
          <a
            target="_blank"
            rel="noreferrer noopener"
            [href]="network.link"
            class="h3 add2"
            *ngFor="let network of socialNetworks"
          >
          <i [ngClass]="[network.class, 'icon-white']"></i></a>
        </div>

        <div class="d-flex flex-column align-items-md-start align-items-center justify-content-start custom-gap">
          <p class="font-weight-bold mt-4 mb-0">Address<br/></p>
          <p class="text-sm mb-0">Floor 3, T-Hub, Plot No 1/C, <br/>
          Sy No 83/1, Madhapur,<br/>
          Serilingamaplly, Hyderabad,<br/>
          Telangana - 500081</p>
  
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.google.com/maps/place/Genz+Technologies+Pvt.+Ltd./@17.433926,78.3776145,18z/data=!4m6!3m5!1s0x3bcb93cc9a471761:0xd15be7bbc06e99dd!8m2!3d17.4338577!4d78.3785679!16s%2Fg%2F11str4zcx7?entry=ttu"
            class="btn custom-button btn-icon font-weight-bold"
          >
            <i class="fa-solid fa-route mr-2"></i>
            Directions
          </a>
        </div>

      </div>
    </div>
  </div>

  <div class="row custom-style">
    <div class="col-12 text-center my-3">
      <p class="font-weight-bold mb-0">
        © {{ year }} GenZ Technologies Pvt Ltd. All rights reserved.
      </p>

      <div
        class="d-flex justify-content-center align-items-center custom-gap"
              >
        <a
          rel="noreferrer noopener"
          routerLink="/terms-and-conditions"
          class="text-sm text-white"
          >Terms & Condition</a
        >
        <span>|</span>
        <a
          rel="noreferrer noopener"
          routerLink="/privacy-policy"
          class="text-sm text-white"
          >Privacy policy</a
        >
        <span>|</span>
        <a
          rel="noreferrer noopener"
          routerLink="/refund-policy"
          class="text-sm text-white"
          >Refund Policy</a
        >

        <span>|</span>
        <a
          rel="noreferrer noopener"
          routerLink="/payment-terms"
          class="text-sm text-white"
          >Payment Terms</a
        >
      </div>
    </div>
  </div>
</footer>
