import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FAQS } from 'src/app/shared/constants/home';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['../../../../app.common.scss', './press.component.scss'],
})
export class PressComponent {
  constructor(deviceService: DeviceDetectorService) {
    if (deviceService.isMobile()) {
    }
  }
  public newsLinks: { url: string; img: string }[] = [
    { url: 'https://t-hub.co/t-hub-and-hexagon-selects-12-startups-for-lab32-cohort-driving-innovation-in-ai-and-digital-reality/', img: 'news1.png' },
    { url: 'https://timesofindia.indiatimes.com/city/hyderabad/t-hub-hexagon-roll-out-new-lab32-cohort/articleshow/107737509.cms', img: 'news2.png' },
    { url: 'https://www.youtube.com/watch?v=hGkUYZISBT8', img: 'news3.png' },
    { url: 'https://themanifest.com/company/genz-technologies-private', img: 'news4.png' },
    { url: 'https://www.einpresswire.com/article/632169104/genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world', img: 'news5.png' },
    { url: 'https://fox2now.com/business/press-releases/ein-presswire/632169104/genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world/', img: 'news6.png' },
    { url: 'https://www.indiamorningtimes.com/article/632169104-genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world', img:'news7.png' },
    { url: 'https://www.entrepreneurshipreporter.com/article/632169104-genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world', img: 'news8.png' },
    { url: 'https://www.crunchbase.com/organization/genzers-technologies', img: 'news9.png' },
    { url: 'https://www.f6s.com/company/genzers', img: 'news10.png' },
    { url: 'https://aws.amazon.com/startups/showcase/startup-details/f78a3f92-aaf7-4f8f-a124-3c573751c9f3?lang=en-US', img: 'news11.png' },
    { url: 'https://yourstory.com/companies/genz-technologies', img: 'news12.png' },
  ];

  ngOnInit(): void {
    this.randomizeNews();
  }

  randomizeNews(): void {
    this.newsLinks.sort(() => Math.random() - 0.5);
  }
}
