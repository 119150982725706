<section class="container custom-margin">
  <div class="row">
    <div class="col-12 col-md-12">
      <div
        id="faq-anchor"
        class="d-flex align-items-center mb-4 custom-gap"
        [attr.data-aos]="responsiveFade"
        data-aos-duration="1000"
        data-aos-anchor="#faq-anchor"
        [attr.data-aos-anchor-placement]="anchorPlacement"
      >
        <div class="icon-box p-3 text-purple font-weight-light">
          <i class="fa-solid fa-question"></i>
        </div>
        <div class="">
          <h3 class="mb-0 font-weight-light lh-1 mt-4">FAQ's</h3>
          <h3 class="font-weight-bold">Frequently asked questions</h3>
        </div>
      </div>

      <ul
        id="faq-anchor2"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activeNav"
        class="nav-pills"
      >
        <li
          [ngbNavItem]="1"
          [attr.data-aos]="responsiveFade"
          data-aos-duration="1000"
          data-aos-anchor="#faq-anchor2"
          [attr.data-aos-anchor-placement]="anchorPlacement"
        >
          <a ngbNavLink>General</a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="faqTemplate"></ng-container>
          </ng-template>
        </li>

        <li
          [ngbNavItem]="2"
          [attr.data-aos]="responsiveFade"
          data-aos-duration="1000"
          data-aos-anchor="#faq-anchor2"
          [attr.data-aos-delay]="200"
          [attr.data-aos-anchor-placement]="anchorPlacement"
        >
          <a ngbNavLink>Pricing</a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="faqTemplate"></ng-container>
          </ng-template>
        </li>

        <li
          [ngbNavItem]="3"
          [attr.data-aos]="responsiveFade"
          data-aos-duration="1000"
          data-aos-anchor="#faq-anchor2"
          [attr.data-aos-delay]="400"
          [attr.data-aos-anchor-placement]="anchorPlacement"
        >
          <a ngbNavLink>Languages</a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="faqTemplate"></ng-container>
          </ng-template>
        </li>

        <li
          [ngbNavItem]="4"
          [attr.data-aos]="responsiveFade"
          data-aos-duration="1000"
          data-aos-anchor="#faq-anchor2"
          [attr.data-aos-delay]="600"
          [attr.data-aos-anchor-placement]="anchorPlacement"
        >
          <a ngbNavLink>AI/ML</a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="faqTemplate"></ng-container>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-4"></div>
    </div>
  </div>
</section>

<ng-template #faqTemplate>
  <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
    <ngb-panel [title]="faq.question" *ngFor="let faq of faqs[activeNav - 1]">
      <ng-template ngbPanelContent>
        <div [innerHTML]="faq.answer"></div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-template>
