import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { MyService } from 'src/app/services/myservice';
@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['../../../../app.common.scss','./investors.component.scss']
})
export class InvestorsComponent {

  canonicalUrl : any;
  ngOnInit(): void {
    this.canonicalUrl = this.myservice.getAbsoluteUrl();
    this.setCanonicalURL(this.canonicalUrl);
  }

  constructor(
    private meta: Meta,
    private myservice : MyService
) {
}
  
  setCanonicalURL(url: string) {
    console.log("canonical url",url)
    const link: HTMLLinkElement | null = this.meta.getTag('rel=canonical') as HTMLLinkElement | null;
    if (link) {
      this.meta.removeTag('rel=canonical');
    }

    const linkElement = this.meta.addTag({
      rel: 'canonical',
      href: url,
    });
  }
  
  
}
