import { Component } from '@angular/core';
import { MENU, PRODUCTS } from '../../constants/navbar';
import { SOCIAL_NETWORKS } from '../../constants/home';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['../../../app.common.scss', './footer.component.scss'],
})
export class FooterComponent {
  date = new Date();
  year = this.date.getFullYear();

  products = PRODUCTS;
  socialNetworks = SOCIAL_NETWORKS;
  menuItems = MENU;
}
