import { Component } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: [ './terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  constructor(private titleService:Title,private meta: Meta) {
    this.meta.updateTag({ name: 'description', content: 'Terms and Conditions | Best AI Products & Services in India & World | Click to know about our T&C Terms and Conditions, LLM, GPT, Chatbots, translation, transcription, transliteration services.'});
	this.titleService.setTitle("Terms and Conditions | Genailia-Gen AI, Linguistic Interfaces, Accelerators, Linguistic Interfaces, Accelerators-Translate,Transcript,Chatbot,LLM,GPT,TTS,ASR");
  }
}
