import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SafeUrlPipe } from './common/components/resources/media/safe.url';  // Import the safe URL pipe

import { AppRoutingModule } from './app-routing.module'; 
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationInterceptor } from './shared/interceptors/authentication.interceptor';
import { TermsAndConditionsComponent } from './common/components/legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './common/components/legal/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './common/components/legal/refund-policy/refund-policy.component';
import { FaqComponent } from './common/components/resources/faq/faq.component';
import { PressComponent } from './common/components/resources/press/press.component';
import { MediaComponent } from './common/components/resources/media/media.component';
import { UsecasesComponent } from './common/components/resources/usecases/usecases.component';
import { LeadershipComponent } from './common/components/company/leadership/leadership.component';
import { AboutComponent } from './common/components/company/about/about.component';
import { PartnersComponent } from './common/components/company/partners/partners.component';
import { CareersComponent } from './common/components/company/careers/careers.component';
import { AssociationsComponent } from './common/components/company/associations/associations.component';
import { InvestorsComponent } from './common/components/company/investors/investors.component';
import { EllmoComponent } from './common/components/privatellm/ellmo.component';
import { AiCapabilitiesComponent } from './common/components/company/ai-capabilities/ai-capabilities.component';
import { AiTransformationComponent } from './common/components/company/ai-transformation/ai-transformation.component';
import { UserProfileComponent } from './common/components/user-profile/user-profile.component';
// import { NgxCaptchaModule } from 'ngx-captcha';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ShellModule } from './shared/components/shell/shell.module';
import { HomeModule } from './common/components/home/home.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CmpComponent } from './common/components/cmp/cmp.component';
import { SocketService } from './services/socket.service';
import { ContactUsPopupComponent } from "./common/components/ctas/contact-us-popup/contact-us-popup.component";
import { PlatformComponent } from './common/components/resources/platform/platform.component';
import { ThesisComponent } from './common/components/company/thesis/thesis.component';

 
@NgModule({
  declarations: [
    AppComponent,
    SafeUrlPipe,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent,
    FaqComponent,
    MediaComponent,
    PressComponent,
    ThesisComponent, 
    UsecasesComponent,
    LeadershipComponent,
    AboutComponent,
    PartnersComponent,
    CareersComponent,
    InvestorsComponent,
    AssociationsComponent,
    EllmoComponent,
    AiCapabilitiesComponent,
    AiTransformationComponent,
    UserProfileComponent,
    CmpComponent,
  
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ServiceWorkerModule.register('./ngsw-worker.js', {
        enabled: environment.production,
    }),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // NgxCaptchaModule,
    NgbModule,
    MDBBootstrapModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ShellModule,
    HomeModule,
 
    AppRoutingModule,
    NgbAlertModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
    }),
    ContactUsPopupComponent
],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    SocketService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
