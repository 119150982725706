import { Component } from '@angular/core';

@Component({
  selector: 'app-leadership',
  templateUrl: './leadership.component.html',
  styleUrls: ['../../../../app.common.scss','./leadership.component.scss']
})
export class LeadershipComponent {	
  constructor() {
  }

}
