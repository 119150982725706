<div class="container-fluid py-4">
	<div class="row mt-86">
		<div class="col-12 text-center mb-4">
			<div class="flex justify-content-center align-items-center">
				<div class="icon-box mx-auto p-3 text-purple font-weight-light">
					<i class="fa-solid fa-layer-group add2"></i>
				</div>
				<h3 class="font-weight-bold mt-4" data-aos="fade-up">
					<span class="font-weight-light">Genailia</span> Platform
				</h3>
			</div>
		</div>
	</div>


	<div class="container-fluid mt-2 overflow-hidden">
		<div class="solutions-container">
			<div class="solutions">
				<h4 class="rotate-text">Solutions</h4>
			</div>
			<div class="cards">
				<div class="row">
					<div class="col-12 col-md-4" *ngFor="let card of tablecards">
						<div class="card h-100">
							<div class="p-2">
								<h2 class="newcardtitle">{{card.title}}</h2>
								<div class="tagcontainer">
									<p *ngFor="let tag of card.tags" class="newcardsvg">
									
										{{ tag.name }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="solutions-container newladded mt-3">
			<div class="solutions">
				<h4 class="rotate-text">The Genailia Platform</h4>
			</div>
			<div class="cards newadded">
				<div class="row mb-2">


					<div class="col-12 col-md-4" *ngFor="let card of tableCards2">
						<div class="card h-100">
							<div class="p-2">
								<h6 class="newcardtitle">{{card.tag}}</h6>

								<div class="random_tag">
									<p *ngFor="let tag of card.tagCards" style="font-size: 12px"
										class="particular_tag">{{ tag }}</p>
								</div>
							</div>
						</div>
					</div>


				</div>
				<div class="al-text">Usable Across Sectors/Industries</div>
				<div class="row">
					<div class="col-12 mb-3">
						<div class="card h-100">
							<div class="p-2">
								<h6 class="newcardtitle">eLLMo - Enterprise Large Language
									Model Outcomes</h6>
								<div class="random_tag">
									<p *ngFor="let tag of tagCards" style="font-size: 12px"
										class="particular_tag">{{ tag }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mb-3">
						<div class="card h-100">
							<div class="p-2">
								<h6 class="newcardtitle">Linguistic Solutions</h6>
								<div class="tagcontainer">
									<p *ngFor="let tag of tags0" class="newcardsvg">
										<svg height="14px" id="Layer_1"
											style="enable-background: new 0 0 512 512" stroke-width="4"
											version="1.1" stroke="#155EEF" fill="#155EEF"
											viewBox="0 0 512 512" width="14px" xml:space="preserve"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g>
                        <g>
                          <path
												d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                        </g>
                      </g>
                    </svg>
										{{ tag.name }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mb-3">
						<div class="card h-100">
							<div class="p-2">
								<h6 class="newcardtitle">Data and Integrations</h6>
								<div class="tagcontainer">
									<p *ngFor="let tag of tags1" class="newcardsvg">
										<svg height="14px" id="Layer_1"
											style="enable-background: new 0 0 512 512" stroke-width="4"
											version="1.1" stroke="#155EEF" fill="#155EEF"
											viewBox="0 0 512 512" width="14px" xml:space="preserve"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g>
                        <g>
                          <path
												d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                        </g>
                      </g>
                    </svg>
										{{ tag.name }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mb-3">
						<div class="card h-100">
							<div class="p-2">
								<h6 class="newcardtitle">Platform Capabilities</h6>
								<div class="tagcontainer">
									<p *ngFor="let tag of tags2" class="newcardsvg">
										<svg height="14px" id="Layer_1"
											style="enable-background: new 0 0 512 512" stroke-width="4"
											version="1.1" stroke="#155EEF" fill="#155EEF"
											viewBox="0 0 512 512" width="14px" xml:space="preserve"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g>
                        <g>
                          <path
												d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                        </g>
                      </g>
                    </svg>
										{{ tag.name }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="solutions-container newladded mt-3">
			<div class="solutions">
				<h4 class="rotate-text">Infrastructure &amp; Models</h4>
			</div>
			<div class="cards newadded">
				<div class="row">
					<div class="col-12 mb-3"></div>
					<div class="col-12 mb-3">
						<div class="card h-100">
							<div class="p-2">
								<h6 class="newcardtitle">Commercial and Open Source Models
								</h6>
								<div class="tagcontainer">
									<p *ngFor="let tag of tags3" class="newcardsvg">
										<svg height="14px" id="Layer_1"
											style="enable-background: new 0 0 512 512" stroke-width="4"
											version="1.1" stroke="#155EEF" fill="#155EEF"
											viewBox="0 0 512 512" width="14px" xml:space="preserve"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g>
                        <g>
                          <path
												d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                        </g>
                      </g>
                    </svg>
										{{ tag.name }}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 mb-3">
						<div class="card h-100">
							<div class="p-2">
								<h6 class="newcardtitle">Enterprise Infrastructure
									Deployments</h6>
								<div class="tagcontainer">
									<p *ngFor="let tag of tags4" class="newcardsvg">
										<svg height="14px" id="Layer_1"
											style="enable-background: new 0 0 512 512" stroke-width="4"
											version="1.1" stroke="#155EEF" fill="#155EEF"
											viewBox="0 0 512 512" width="14px" xml:space="preserve"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g>
                        <g>
                          <path
												d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                        </g>
                      </g>
                    </svg>
										{{ tag.name }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</div>
