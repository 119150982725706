import { Component } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: [ './refund-policy.component.scss']
})
export class RefundPolicyComponent {
  constructor(private titleService:Title,private meta: Meta) {
    this.meta.updateTag({ name: 'description', content: 'Refund Policy | Best AI Products & Services in India & World | Click to know about our refund policy, LLM, GPT, Chatbots, translation, transcription, transliteration services.'});
	this.titleService.setTitle("Refund Policy | Genailia-Gen AI, Linguistic Interfaces, Accelerators, Linguistic Interfaces, Accelerators-Translate,Transcript,Chatbot,LLM,GPT,TTS,ASR");
  }
}
