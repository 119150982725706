import { Injectable } from "@angular/core";
import { Location } from "@angular/common";


@Injectable({
    providedIn: 'root',
})
export class MyService {
    constructor(
        private location : Location,
    ) { }
    getAbsoluteUrl(): string {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const path = this.location.path(); // Gets the path from the Angular router
    
        return `${protocol}//${host}${path}`;
      }
}