import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { MyService } from 'src/app/services/myservice';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['../../../../app.common.scss','./about.component.scss']
})
export class AboutComponent {

  canonicalUrl : any;
  ngOnInit(): void {
    this.canonicalUrl = this.myservice.getAbsoluteUrl();
    this.setCanonicalURL(this.canonicalUrl);
    this.addJSONLD();
  }

  constructor(
    private meta: Meta,
    private myservice : MyService,
    private titleService : Title
) {
  this.meta.updateTag({
    name: 'description', content: 'Genailia | About Us - Discover our mission & AI-driven language solutions. Learn about our commitment to innovation, and cutting-edge technology.'
  });
  this.titleService.setTitle("Genailia | About Us - Generative AI, Language Interfaces");  
}
private addJSONLD(): void {
  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.text = `
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "About Us",
    "description": "Learn about Genailia's mission,values,and AI-driven language solutions.Discover our commitment to innovation, excellence, and cutting-edge technology.",
    "url": "https://www.genailia.com/about"
  }`;
  document.head.appendChild(script);
}
  setCanonicalURL(url: string) {
    console.log("canonical url",url)
    const link: HTMLLinkElement | null = this.meta.getTag('rel=canonical') as HTMLLinkElement | null;
    if (link) {
      this.meta.removeTag('rel=canonical');
    }

    const linkElement = this.meta.addTag({
      rel: 'canonical',
      href: url,
    });
  }
  
  
}
