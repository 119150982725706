<div class="glossary jumbotron jumbotron-fluid  text-center">
    <br/> <br/>  
    <h2>Our Notable Press Coverage and References</h2>
    <p class="h5">Stay informed with the latest updates, product launches, and important news from the world of Artificial Intelligence.</p> 
</div>
<div class="container mt-3">
  <div class="row">
    <div class="col-md-12" *ngFor="let link of newsLinks">
      <a href="{{ link.url }}" target="_blank" rel="nofollow noreferrer">
        <img src="assets/img/{{ link.img }}" class="img-fluid" alt="news coverage">
        <br/><p class="text-center">{{ link.url }}</p>
      </a><br/><br/><hr/><hr/><br/><br/>
    </div>
  </div>
</div>


