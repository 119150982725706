
export const PRODUCTS = [
    { title: 'Text Translation', link: '/aiproducts/text-translate' },
    { title: 'Transliteration', link: '/aiproducts/transliteration-universal-language-typing' },
    { title: 'Text to Speech - TTS', link: '/aiproducts/text-to-speech' },
    { title: 'Speech to Text', link: '/aiproducts/speech-to-text-realtime' },
    { title: 'Language Detection', link: '/aiproducts/language-detection' },
    { title: 'Document Translator', link: '/aiproducts/pdf-translator' },
    { title: 'Optical Character Recognition (OCR)', link: '/aiproducts/optical-character-recognition' },
    { title: 'Unstructured Data Extractor', link: "/aiproducts/keyword-data-extraction" },
    { title: 'Youtube Summary', link: '/aiproducts/youtube-summary' },
    { title: 'Youtube Transcript/Translation', link: '/aiproducts/youtube-transcript-translation' },
];

export const COMPANY = [
    { title: 'About', link: '/about' },
    { title: 'Leadership', link: '/leadership' },
    { title: 'Careers', link: '/careers' },
    { title: 'Partners', link: '/partners' },
    { title: 'Investors', link: '/investors' },
    { title: 'Thesis', link: '/thesis' },
    { title: 'Ask Genailia GPT', link: 'https://chatgpt.com/g/g-5NQWMkLA6-genailia-gpt' },
    { title: 'Associations', link: '/associations' },
];

export const RESOURCES = [
    { title: 'Announcements', link: '/announcement' },
    { title: 'AI Summaries', link: '/ai-branches' },
    { title: 'Media Library', link: '/media' },
    { title: 'Press Coverage', link: '/press' },
    { title: 'FAQ', link: '/faq' },
    { title: 'Survey', link: 'https://surveys.genailia.com/zs/Nz60jY' },
    { title: 'Solutions', link: '/solutions' },
    { title: 'Platform', link: '/platform' },
    { title: 'Glossary', link: '/glossary' },
    { title: 'Blog', link: '/blog' },
];

export const MEMBERS = [
    { title: 'Login', url: '/login' },
    { title: 'Sign Up', url: '/signup' },
];


export const MENU = [
    {
        title: 'Home',
        link: 'https://www.genailia.com/home',
    },
    {
        title: 'Pricing',
        link: 'https://www.genailia.com/pricing',
    },
    {
        title: 'About Us',
        link: 'https://www.genailia.com/about',
    },
        {
        title: 'Resources',
        link: 'https://www.genailia.com/resources',
    },
    {
        title: 'Contact',
        link: 'https://www.genailia.com/contact',
    },
    {
        title: 'Platform',
        link: 'https://www.genailia.com/platform',
    },
    {
        title: 'Solutions',
        link: 'https://www.genailia.com/solutions',
    },
    {
        title: 'Blog',
        link: 'https://www.genailia.com/blog',
    },
];

export const FEATURES = [
    {

        title: "RAG (Retrieval Augmented Generation)",
        description: "Explore Q&A with RAG's LLM-based Model. Leverage LLM-based PaaS AI, Large Language Model API, and Unlock Generative AI Search for Enhanced Efficiency"
    },
    {

        title: "Secure Role-Based Access",
        description: "Fortify Data Security with Role-Based Access to Empower Teams through Generative AI and Private GPT. Customize and Fine-Tune Models in Enterprise and Organizational Environments."
    },
    {

        title: "Flexible Pricing Models",
        description: "Versatile Pricing Models for Small LLM, Small Language Model, LLM-based PaaS AI, and the Best Large Language Model AI. Unlock AI for Language with GPT in Various Languages."
    },
    {

        title: "Flexible Deployment Options",
        description: "Opt for SaaS, PaaS, On-Premises, or Private Cloud, Empowered by LLM Vector Search, LLM-based Entity Recognition with Prompt, Large Language Model-based API, Private GPT."
    },
    {

        title: "Intuitive Interface",
        description: "Next-Level Interface, Enhanced with Search with AI, LLM-based Q&A Model, PaaS AI, Large Language Model API, Generative AI Search, and Advanced LLM Features. Explore Best Large Language Model AI."
    },
    {

        title: "Performance & Accuracy",
        description: "Large Language Model API, and Generative AI Search. Harness the Power of Generative AI Models, Small LLM, LLM Vector Search, Entity Recognition, and Sentiment Analysis with Prompts."
    },
    {

        title: "Cost-Effective",
        description: "Affordability with Advanced Capabilities – Search with AI, LLM-based Q&A Model, PaaS AI. Explore the Best in Large Language Model AI, Understand Large Models, Private GPT and Private LLM"
    },
    {

        title: "Prompt Templates",
        description: "Refine Interactions with Prompt Templates: Perform Sentiment Analysis with Generative AI and Unlock Enhanced Searching with AI with Best Large Language Mode"
    },
    {

        title: "Multi-Level Custom Prompts",
        description: "Explore LLM-based Entity Recognition with Prompt and Prompt Sentiment Analysis with Generative AI. Gain In-Depth Understanding with Large Language Models Overview, Including Private GPT and Private LLM."
    },

]