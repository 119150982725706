import { NgModule } from '@angular/core';
import { AboutComponent } from './common/components/company/about/about.component';
import { RouterModule, Routes } from '@angular/router';
import { PricingComponent } from './common/components/pricing/pricing.component';
import { AuthGuard } from './login/auth.guard';
import { TermsAndConditionsComponent } from './common/components/legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './common/components/legal/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './common/components/legal/refund-policy/refund-policy.component';
import { FaqComponent } from './common/components/resources/faq/faq.component';
import { PressComponent } from './common/components/resources/press/press.component';
import { MediaComponent } from './common/components/resources/media/media.component';
import { UsecasesComponent } from './common/components/resources/usecases/usecases.component';
import { LeadershipComponent } from './common/components/company/leadership/leadership.component';
import { AssociationsComponent } from './common/components/company/associations/associations.component';
import { PartnersComponent } from './common/components/company/partners/partners.component';
import { InvestorsComponent } from './common/components/company/investors/investors.component';
import { CareersComponent } from './common/components/company/careers/careers.component';
import { EllmoComponent } from './common/components/privatellm/ellmo.component';
import { ContactComponent } from './common/components/ctas/contact/contact.component';
import { AiCapabilitiesComponent } from './common/components/company/ai-capabilities/ai-capabilities.component';
import { AiTransformationComponent } from './common/components/company/ai-transformation/ai-transformation.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { Shell } from './shared/components/shell/shell.service';
import { EBookComponent } from './common/components/resources/e-book/e-book.component';
import { CmpComponent } from './common/components/cmp/cmp.component';
import { PaymentTermsComponent } from './common/components/legal/payment-terms/payment-terms.component';
import { GetDemoComponent } from './common/components/ctas/get-demo/get-demo.component';
import { GlossaryComponent } from './common/components/resources/glossary/glossary.component';
import { KeywordDetailsComponent } from './common/components/resources/keyword-details/keyword-details.component';
import { PlatformComponent } from './common/components/resources/platform/platform.component';
import { ThesisComponent } from './common/components/company/thesis/thesis.component';
const routes: Routes = [
    {
        path: '', redirectTo: '/home', pathMatch: 'full',
    },
    Shell.childRoutes([

        {
            path: 'home2',
            loadChildren: () =>
                import('./common/components/home/home-routing.module').then(
                    (m) => m.HomeRoutingModule
                ),
        },
        {
            path: 'home',
            loadChildren: () =>
                import('./common/components/home/home-routing.module').then(
                    (m) => m.HomeRoutingModule
                ),
        },
        {
            path: 'aiproducts',
            loadChildren: () =>
                import('./aiproducts/aiproducts.module').then(
                    (m) => m.TranslatorModule
                ),
            canActivate: [AuthGuard],
        },
        {
            path: 'platform',
            component: PlatformComponent
        },
        {
            path: 'thesis',
            component: ThesisComponent
        },

        {
            path: 'blog',
            loadChildren: () =>
                import('./blog/blog.module').then((m) => m.BlogModule),
        },
        {
            path: 'announcement',
            loadChildren: () =>
                import("./announcement/announcement.module").then((m) => m.AnnouncementModule)
        },
        {
            path: 'ai-branches',
            loadChildren: () =>
                import("./ai-branches/ai-branch.module").then((m) => m.AIBranchModule)
        },        
        {
            path: 'solutions',
            loadChildren: () => import("./solutions/solution.module").then((m) => m.SolutionModule)
        },
        {
            path: 'pricing',
            component: PricingComponent,
        },
        {
            path: 'case-studies',
            component: PricingComponent,
        },
        {
            path: 'terms-and-conditions',
            component: TermsAndConditionsComponent,
        },
        {
            path: 'privacy-policy',
            component: PrivacyPolicyComponent,
        },
        {
            path: 'refund-policy',
            component: RefundPolicyComponent,
        },
        {
            path: 'faq',
            component: FaqComponent,
        },
        {
            path: 'press',
            component: PressComponent,
        },
        {
            path: 'media',
            component: MediaComponent,
        },
        {
            path: 'usecases',
            component: UsecasesComponent,
        },
        {
            path: 'e-book',
            component: EBookComponent,
        },
        {
            path: 'about',
            component: AboutComponent,
        },
        {
            path: 'leadership',
            component: LeadershipComponent,
        },
        {
            path: 'careers',
            component: CareersComponent,
        },
        {
            path: 'associations',
            component: AssociationsComponent,
        },
        {
            path: 'partners',
            component: PartnersComponent,
        },
        {
            path: 'investors',
            component: InvestorsComponent,
        },
        {
            path: 'ellmo',
            component: EllmoComponent,
        },
        {
            path: 'contact',
            component: ContactComponent,
        },
        {
            path: 'ai-capabilities',
            component: AiCapabilitiesComponent,
        },
        {
            path: 'ai-transformation',
            component: AiTransformationComponent,
        },
        {
            path: 'payment-terms',
            component: PaymentTermsComponent
        },
        {
            path: 'get-demo',
            component: GetDemoComponent,
        },
        {
            path: 'glossary',
            component: GlossaryComponent,
        },
        {
            path: 'glossary/:_id',
            component: KeywordDetailsComponent,
        },
        { path: 'cmpn', component: CmpComponent },

        {
            path: '404',
            component: PageNotFoundComponent,
        },


        { path: '**', redirectTo: '/404' },
    ])

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
