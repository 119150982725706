<!--  TODO: Remove this comment 
<app-membership-restriction *ngIf="!isLoggedIn"></app-membership-restriction>
 -->
<br />
<br />
<br />
<br />
<ng-template #content>
	<div class="modal-header">
		<h1 class="modal-title justify-center h1-as-h5" id="modal-basic-title">Membership Notes - Please Read ...</h1>
	</div>	
<div class="modal-body p-4  justify-content-center">
	<p class="custom-padding">Acquaint yourself with the below
		notes before purchasing a subscription for a smooth on-boarding
		experience.</p>

		<h6 class="h6-as-b">
			<u>Enterprise Subscriptions</u>
		</h6>		
	<ul>
		<li>We understand your requirements might vary.</li>
		<li><a href="https://www.genailia.com/contact" target="_blank">Talk
				to us</a> if you need a different plan or have a need to support more
			users.</li>
		<li>Generative AI integrations can be performed as required with
			quick turn around times.</li>
		<li>Need on-prem or private-cloud deployment? <a
			href="https://www.genailia.com/contact" target="_blank">Talk to
				us</a>.
		</li>
	</ul>

	<h6 class="h6-as-b">
		<u>Volume Discounts</u>
	</h6>	
	<ul>
		<li>Once again, we understand your requirements might vary. For
			instance, you want to use only the translation and TTS components and
			may be looking for volume discounts.</li>
		<li><a href="https://www.genailia.com/contact" target="_blank">Talk
				to us</a> and we can carve out a plan that is suitable to you.</li>
	</ul>

	<h6 class="h6-as-b">
		<u>API Access</u>
	</h6>
	<ul>
		<li>API access is included with Enterprise subscription and does
			not have to be purchased separately.</li>
		<li>API access for other subscription types will have to be
			purchased separately.</li>
		<li>API access, if purchased, is valid for 1 month from the date
			of purchase or for the remaining duration of the current base plan,
			whichever is shorter. API access will be valid only with the presence
			of an underlying base plan.</li>
		<li>API access can be purchased at any time using <a
			target="_blank"
			href="https://billing.genailia.com/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-API-001">this
				link (INR)</a> or <a target="_blank"
			href="https://billing.genailia.com/subscribe/3be170f9ee05ea4ba3c666b3ffcf6069031c7f784fd7d1a5aa970f34fab5430f/GEN-API-001">this
				one (USD)</a>.
		</li>
	</ul>

	<h6 class="h6-as-b"><u>General</u></h6>
		
	<ul>
		<li>You can verify your subscription by visiting your profile on
			the top right corner and clicking on 'Payments'.</li>
		<li>The 'Payments' link will work only after the purchase of your
			first subscription.</li>
		<li><b>Use the same email address during payment as used in
				this account. Otherwise the sync will not be seamless.</b></li>
	</ul>

	<h6 class="h6-as-b">
		<u>GST Considerations</u>
	</h6>

	<ul>
		<li>GST amounts are only applicable for customers based in India
			and will be removed once a different country is selected.</li>
		<li>IGST/CGST+SGST will be considered once the correct Indian
			State is considered during the checkout process.</li>
	</ul>
	<h6 class="h6-as-b">
		<u>Pay-As-You-Go</u>
	</h6>
		

	<ul>
		<li>Pay-as-you-go subscriptions are pre-paid. Please load
			sufficient balance as you deem necessary before using the account.</li>
		<li>For the pay-as-you-go subscriptions, a minimum prescribed
			amount has to be purchased. Any amount of credits can be purchased by
			increasing the quanity.</li>
		<li>The pay-as-you-go based accounts will be charged based on the
			actual usage. The amount will be valid for 1 year.</li>
	</ul>


	<div class="d-flex  justify-content-center">
		<button class="w-40 btn btn-outline-danger btn-sm"
			(click)="closeModal()">
			<i class="bi bi-tag-fill"></i> I Acknowledge
		</button>

	</div>
</div>
</ng-template>

<ng-template #content2>
<div class="modal-header">
	<h5 align="center" class="modal-title justify-center"
		id="modal-basic-title">Free plan limits</h5>
</div>
<div class="modal-body p-4  justify-content-center">
	<p class="custom-padding">Validity - Unlimited | Users - Single
		| Translation (Plain Text/Rich Text) - 5000 Characters |
		Transliteration - 5000 Characters | Document Translation - 5 Pages |
		Speech Translation - 5 Minutes | Video Translation - 5 Minutes | Live
		Translation - 5 Minutes | Video Transcription - 5 Minutes | Audio
		Transcription - 5 Minutes | Live Transcription - 5 Minutes | TTS
		Generation - 5000 Characters | Voice Cloning - 1 Voices | Ellmo -
		Chatbot - QnA - 2000 Words | Ellmo - Chatbot - Ingestion - 2000 Words
		| Detect Lang - Text - 10000 Characters | Detect Lang - Voice - 5
		Minutes | Image OCR - 10 Images | Document OCR - 10 Pages | TTS Voices
		List - Standard Voices | Support - None | Additional Features - None</p>
</div>

<div class="d-flex  justify-content-center">
	<button class="w-40 btn btn-outline-danger btn-sm"
		(click)="closeModal()">
		<i class="bi bi-tag-fill"></i> Sounds Good!
	</button>

</div>
<br />
</ng-template>

<br />

<div class="d-flex  justify-content-center">
	<button class="w-40 btn btn-outline-danger btn-sm"
		(click)="openLink('https://billing.genailia.com/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-API-001')">
		<i class="bi bi-tag-fill"></i> API Access (INR)
	</button>
	<button class="w-40 btn btn-outline-danger btn-sm" (click)="open()">
		<i class="bi bi-tag-fill"></i> Membership Notes
	</button>
	<button class="w-40 btn btn-outline-danger btn-sm"
		(click)="openLink('https://billing.genailia.com/subscribe/3be170f9ee05ea4ba3c666b3ffcf6069031c7f784fd7d1a5aa970f34fab5430f/GEN-API-001')">
		<i class="bi bi-tag-fill"></i> API Access (USD)
	</button>
	<button class="w-40 btn btn-outline-danger btn-sm" (click)="openFree()">
		<i class="bi bi-tag-fill"></i> Free Plan Limits
	</button>
</div>


<div id="zf-widget-root-id"></div>
<a name="chapter4"></a>



<br />
<br />
<br />
<br />
<div class="subscription-faq">

	<h3>Subscription FAQ's</h3>

	<div>
		<p>
			<strong>Are you GST compliant?</strong>
		</p>
		<p>Yes, we are fully GST compliant and ensure that all our billing
			and invoicing practices adhere to the GST regulations.</p>
		<br />
        <p>
            <strong>Are enterprise limits per user or for all 12 users combined?</strong>
        </p>
        <p>Enterprise limits comes at a heavily discounted price. The limits mentioned are per user!</p>
        <br />  		
		<p>
			<strong>What does PCI compliance mean, and are you PCI
				compliant?</strong>
		</p>
		<p>PCI compliance ensures that we follow the Payment Card Industry
			Data Security Standard (PCI DSS) to secure credit card transactions.
			We are PCI compliant, which means your payment data is handled with
			the highest level of security.</p>
		<br />
		<p>
			<strong>Is your company Udyam registered?</strong>
		</p>
		<p>Yes, we are Udyam registered. This certification recognizes us
			as a micro, small, or medium enterprise under the Government of
			India&apos;s Udyam Registration scheme.</p>
		<br />
		<p>
			<strong>Do you comply with the Shops and Establishment Act?</strong>
		</p>
		<p>Yes, we comply with the Shops and Establishment Act, ensuring
			that our business practices align with the regulations governing
			working conditions and employment practices in commercial
			establishments.</p>
		<br />
		<p>
			<strong>How does your compliance benefit me as a subscriber?</strong>
		</p>
		<p>Our compliance with GST, PCI, Udyam registration, and the Shops
			and Establishment Act ensures that you receive services from a
			legally and ethically responsible company. It provides you with peace
			of mind knowing that your data is secure and that all transactions
			are conducted within the legal framework.</p>
	</div>
	<br />
	<div>
		<p>
			<strong>How do you store and secure my data?</strong>
		</p>
		<p>We use Zoho, an industry-standard platform, to store and secure
			your data. Zoho is trusted by thousands of enterprise clients
			worldwide, ensuring round-the-clock data security and reliability. We
			implement stringent security measures to protect your data from
			unauthorized access.</p>
		<br />
		<p>
			<strong>Is my payment information secure with you?</strong>
		</p>
		<p>Yes, your payment information is secure. We do not store your
			card information on our servers. Instead, we rely on Zoho&apos;s
			secure payment processing system, which adheres to PCI DSS standards.
			This ensures your payment data is handled with the highest level of
			security and encryption.</p>
		<br />
		<p>
			<strong>Do you store my credit card information?</strong>
		</p>
		<p>No, we do not store your credit card information. All payment
			details are securely processed and managed by Zoho, which complies
			with PCI DSS regulations to ensure the safety and privacy of your
			payment data.</p>
		<br />
		<p>
			<strong>Why did you choose Zoho for data storage and
				security?</strong>
		</p>
		<p>We chose Zoho for its robust security features and reputation
			as an industry leader. Zoho has thousands of enterprise clients
			globally and provides round-the-clock reliability and data
			protection. This ensures that our clients' data is always secure and
			accessible.</p>
	</div>
</div>

<br />
<br />
<br />
<br />
<br />
&nbsp;
