import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlossaryDataService {

  glossaryItems: any = [
    {
      "_id":"machine-learning",
      "keyword": "Machine Learning",
      "description": "A subset of AI where algorithms learn from data to make predictions or decisions without being explicitly programmed."
    },
    {
      "_id":"neural-networks",
      "keyword": "Neural Networks",
      "description": "A series of algorithms modeled after the human brain, used to recognize patterns and interpret sensory data."
    },
    {
      "_id":"deep-learning",
      "keyword": "Deep Learning",
      "description": "A type of machine learning that uses multi-layered neural networks to analyze various levels of data representation."
    },
    {
      "_id":"natural-language-processing",
      "keyword": "Natural Language Processing",
      "description": "A field of AI focused on the interaction between computers and humans through natural language."
    },
    {
      "_id":"computer-vision",
      "keyword": "Computer Vision",
      "description": "A branch of AI that enables computers to interpret and make decisions based on visual data from the world."
    },
    {
      "_id":"reinforcement-learning",
      "keyword": "Reinforcement Learning",
      "description": "A type of machine learning where an agent learns to make decisions by taking actions in an environment to maximize rewards."
    },
    {
      "_id":"supervised-learning",
      "keyword": "Supervised Learning",
      "description": "A type of machine learning where the model is trained on labeled data to predict outcomes for new, unseen data."
    },
    {
      "_id":"unsupervised-Learning",
      "keyword": "Unsupervised Learning",
      "description": "A type of machine learning where the model learns patterns from unlabeled data without specific guidance."
    },
    {
      "_id":"artificial-neural-network",
      "keyword": "Artificial Neural Network",
      "description": "A computing system inspired by biological neural networks that learns to perform tasks by considering examples."
    },
    {
      "_id":"ai-ethics",
      "keyword": "AI Ethics",
      "description": "The field concerned with the moral implications and responsible use of AI, including issues of bias, privacy, and transparency."
    }
  ];

  constructor() { }

  getAllkeywords(){
    return this.glossaryItems
  }

  getKewordById(_id: any){
    return this.glossaryItems.find((glossaryItem:any)  => glossaryItem._id === _id);
  }
}
