import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/ngsw-worker.js').then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        console.log('installingWorker '+installingWorker);
        installingWorker!.onstatechange = () => {
          if (installingWorker!.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('New content is available; please refresh.');
              if (confirm("New version available. Do you want to update?")) {
                window.location.reload();
              }
            } else {
              console.log('Content is cached for offline use.');
            }
          }
        };
      };
    }).catch(error => console.error('Error during service worker registration:', error));
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
