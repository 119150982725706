import { Component } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  constructor(private titleService:Title,private meta: Meta) {
    this.meta.updateTag({ name: 'description', content: 'Privacy Policy | Best AI Products & Services in India & World | Click to know about our privacy policy, LLM, GPT, Chatbots, translation, transcription, transliteration services.'});
	this.titleService.setTitle("Privacy Policy | Genailia-Gen AI, Linguistic Interfaces, Accelerators, Linguistic Interfaces, Accelerators-Translate,Transcript,Chatbot,LLM,GPT,TTS,ASR");
  }

}
