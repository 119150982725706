import { Component ,inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { GlossaryDataService } from '../glossary-data.service';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-keyword-details',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './keyword-details.component.html',
  styleUrls: ['./keyword-details.component.scss']
})
export class KeywordDetailsComponent {

  route: ActivatedRoute = inject(ActivatedRoute)
  
  glossaryService  = inject(GlossaryDataService)
  keywordDetails : any | undefined;

  constructor(){
    const _id = this.route.snapshot.params["_id"]
    this.keywordDetails = this.glossaryService.getKewordById(_id)
  }

}
