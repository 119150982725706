import { Component, ViewEncapsulation } from '@angular/core';
import { FEATURES } from 'src/app/shared/constants/navbar';
import { MyService } from 'src/app/services/myservice';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-about',
  templateUrl: './ellmo.component.html',
  styleUrls: ['../../../app.common.scss','./ellmo.component.scss'],
  encapsulation: ViewEncapsulation.Emulated // or ShadowDom
})
export class EllmoComponent {

  features = FEATURES;
  canonicalUrl : any;
  ngOnInit(): void {
    this.canonicalUrl = this.myservice.getAbsoluteUrl();
    this.setCanonicalURL(this.canonicalUrl);
  }
  constructor(
    private myservice : MyService,
    private meta : Meta
  ) { 
  }
  setCanonicalURL(url: string) {
    console.log("canonical url",url)
    const link: HTMLLinkElement | null = this.meta.getTag('rel=canonical') as HTMLLinkElement | null;
    if (link) {
      this.meta.removeTag('rel=canonical');
    }

    const linkElement = this.meta.addTag({
      rel: 'canonical',
      href: url,
    });
  }
  data = [
    'search with ai',
    'llm based q&a model',
    'llm based paas ai',
    'large language model based api',
    'llm based generative ai search',
    'generative ai models',
    'small language model',
    'small LLM',
    'llm vector search',
    'llm based entity recognition with prompt',
    'prompt sentiment analysis with generative ai',
    'best large language model ai',
    'understanding large language models',
    'large language models overview',
    'large language models gpt',
    'private gpt',
    'private llm',
    'train your own model',
    'fine-tuning large language models',
    'train your own language model',
    'ai for operations',
    'ai in enterprise',
    'ai at workplace',
    'llm/gpt llm for workplace',
    'llm/gpt for enterprise',
    'llm/gpt for enterprise',
    'llm/gpt for organization',
    'ai for language',
    'language gpt',
    'GPT in other language',
    'gpt in native language',
    'search audio/video with llm'
  ];
  getWordsByIndex(index: number): string {
    const startIndex = index * 2;
    const endIndex = startIndex + 1;

    if (startIndex < 0 || endIndex >= this.data.length) {
      return "Invalid index";
    }

    const word1 = this.data[startIndex];
    const word2 = this.data[endIndex];

    return `${word1} ${word2}`;
  }
}
