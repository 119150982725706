import { Component } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-ai-transformation',
  templateUrl: './ai-transformation.component.html',
  styleUrls: ['../../../../app.common.scss','./ai-transformation.component.scss']
})
export class AiTransformationComponent {
  constructor(private titleService:Title,private meta: Meta) {
    this.meta.updateTag({ name: 'description', content: 'AI Products & Services - Genailia offers LLMs, GPTs, ChatGPT integration, OCR, Translation, Transcription, Transliteration, Chatbot, Document/PDF Translation products and services required for your AI Transformations.'});
	this.titleService.setTitle("AI Transformation | Genailia-Gen AI, Linguistic Interfaces, Accelerators, Linguistic Interfaces, Accelerators-Translate,Transcript,Chatbot,LLM,GPT,TTS,ASR");
  }
}
