import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { COMPANY, MEMBERS, PRODUCTS, RESOURCES } from '../../constants/navbar';
import { Router } from '@angular/router';
import { ContactUsServiceService } from 'src/app/common/components/ctas/contact-us-service.service';
import { delay, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BlogService } from 'src/app/blog/services/blog.service';

@Injectable({
    providedIn: 'root',
})
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['../../../app.common.scss', './header.component.scss'],
})
export class HeaderComponent implements OnInit {
    private readonly backendUrl = 'https://api.genailia.com/api/v2/backend'; // Replace with your actual backend URL
    isBackendUp: boolean = true;
    userData: any;
    special: boolean = false;
    shabdai: boolean = false;


    isLoggedIn: boolean = false;
    toggle: boolean = false;

    products = PRODUCTS;
    company = COMPANY;
    resources = RESOURCES;
    members = MEMBERS;

    menuHidden = true;

    constructor(private authService: AuthService, private blogService: BlogService, private http: HttpClient, private router: Router, private contactUsService: ContactUsServiceService) { }

    openContactUsForm() {
        this.contactUsService.showPopup();
    }

    items = [
        {
            icon: 'fa-solid fa-bullhorn', // Replace with your desired icon class
            title: 'Announcements',
            description: 'Learn the basics to get started.'
        }, 
        {
            icon: 'fa-solid fa-book-open', // Replace with your desired icon class
            title: 'AI Summaries',
            description: 'Learn more about AI.'
        },
        {
            icon: 'fa-solid fa-photo-film',
            title: 'Media Library',
            description: 'Discover advanced techniques.'
        },
        {
            icon: 'fa-solid fa-newspaper',
            title: 'Press Coverage',
            description: 'Get help and support.'
        },
        {
            icon: 'fa-solid fa-circle-question',
            title: 'FAQ',
            description: 'Get help and support.'
        },
        {
            icon: 'fa-solid fa-square-poll-vertical',
            title: 'Survey',
            description: 'Get help and support.'
        },
        {
            icon: 'fa-solid fa-people-arrows',
            title: 'Solutions',
            description: 'Get help and support.'
        },
        {
            icon: 'fa-solid fa-layer-group',
            title: 'Platform',
            description: 'Get help and support.'
        },
        {
            icon: 'fa-solid fa-book-open',
            title: 'Glossary',
            description: 'Get help and support.'
        },
        {
            icon: 'fa-solid fa-file-pen',
            title: 'Blogs',
            description: 'Get help and support.'
        },

    ];

    headerBlogs: any[] = [];



    ngOnInit(): void {
        this.isLoggedIn = this.authService.isUserLoggedIn();
        const email = this.authService.getEmail();

        if (email === "trialuser.genzers@gmail.com") {
            this.special = true
        }
        if (email === "swati@shabdai.com" || email === "kriti@shabdai.com") {
            this.shabdai = true
        }

        if (email) {
            this.authService.getMember(email).subscribe(res => {
                if (res) {
                    this.userData = res;
                }
            }, err => {

            });
        }

        this.checkBackendStatus().subscribe({
            next: (isUp) => {
                this.isBackendUp = isUp;
            },
            error: (error) => {
                this.isBackendUp = false;
            }
        });
        this.getRelatedBlogs();


    }

    getRelatedBlogs() {
        this.blogService.getBlogPosts(undefined, false, undefined, 3, "random").subscribe(res => {
            console.log(res);
            this.headerBlogs = res;
        })

    }

    formatToSlugWithGuid(input: string, guid: string): string {
        const slug = input
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, '')
            .trim()
            .replace(/\s+/g, '-');

        return `${slug}-${guid}`;
    }

    logout() {
        this.authService.logout();
    }

    toggleMenu() {
        this.menuHidden = !this.menuHidden;
    }


    checkBackendStatus_bkp() {
        // Always return true regardless of the backend status
        return Promise.resolve(true);
    }

    checkBackendStatus() {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain'); // Set the appropriate content type for your backend

        return this.authService.fetchToken().pipe(
            switchMap((token) => {
                const headers = {
                    Authorization: 'Bearer ' + token,
                };
                console.log('token is ' + token);
                return this.http
                    .get(this.backendUrl, { headers, responseType: 'text' })
                    .toPromise()
                    .then((response) => {
                        // Check the response text to determine if the backend is up or down
                        if (response === 'Backend Services are Up!') {
                            return true; // Backend is up and running
                        } else {
                            return false; // Backend is down
                        }
                    })
                    .catch((error) => false); // Backend is down
            })
        );


    }
    isGenerativeAI(product: { title: string; link: string }): boolean {
        return product.title === 'Generative AI' || product.title === 'Survey' || product.title === 'Ask Genailia GPT';
    }

}
